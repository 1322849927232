<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto">
							<v-col
								align="center"
							>
								<v-card class="mx-auto">
									<v-img
										src="https://www.revistaneo.com/sites/default/files/2020-10/fondos-padres.jpg"
										height="80px"
									>
									
										<span class="white--text text-h4 mt-8">
											<b>
												<h4 style="font-size:; margin-top: 30px">
													{{  general.NameProcess}}
												</h4> </b
											>
										</span>
									<!-- </v-btn> -->
									</v-img>
									<v-divider></v-divider>

									<v-card-subtitle>
										<h5 style="font-size: 20px;">{{ 'PN ' + general.AmountWeightNet.toFixed(3) + ' TM'}}</h5>
										<br>
										<!-- <h5 style="font-size: 20px;">{{ 'PB ' + general.AmountWeight.toFixed(3) + ' Ton'}}</h5> -->
									</v-card-subtitle>

									<v-divider></v-divider>


									<!-- <v-card-actions>
										<v-btn rounded @click="click(item)" width="100%"  small color="success" block>
											Ver	detalle
										</v-btn>
									</v-card-actions> -->
								</v-card>
							</v-col>
						</v-row>
						<v-row style="margin: auto">
							<v-col
								align="center"
								cols="12"
								lg="3"
								md="6"
								v-for="item in itemsDataStock"
								:key="item.TypeProcess"
							>

								<v-card class="mx-auto">
									<v-img
										src="https://www.revistaneo.com/sites/default/files/2020-10/fondos-padres.jpg"
										height="80px"
									>
										<!-- <v-btn
											style="
												margin-top: 30px;
												margin-bottom: 20px;
												display: flex;
												justify-content: space-evenly;
											"../../../assets/phtobalanse.jpg
											width="100%"
										
										
										> -->
										
										<span class="white--text text-h4 mt-8">
											<b>
												<h4 style="font-size:; margin-top: 30px">
													{{  item.NameProcess}}
												</h4> </b
											>
										</span>
									<!-- </v-btn> -->
									</v-img>
									<v-divider></v-divider>


									<!-- <v-card-title>
										<h2 style="text-transform: uppercase;font-size: 25px;">{{ item.AmountWeight + ' Ton'}}</h2>
									</v-card-title> -->

									<!-- <v-card-subtitle class="mt-1">
										<h5 style="font-size: 25px;">{{ 'PN ' + item.AmountWeightNet + ' Ton'}}</h5>
									</v-card-subtitle> -->
									<v-card-subtitle>
										<h5 style="font-size: 20px;">{{ 'PN ' + item.AmountWeightNet + ' TM'}}</h5>
										<br>
										<!-- <h5 style="font-size: 20px;">{{ 'PB ' + item.AmountWeight + ' Ton'}}</h5> -->
									</v-card-subtitle>
									
									<!-- <v-card-subtitle>
										<v-progress-linear
											v-model="skill"
											color="green"
											height="25"
											
										>
											<template v-slot:default="{ value }">
												<strong>{{ Math.ceil(value) }}%</strong>
											</template>
										</v-progress-linear>
									</v-card-subtitle> -->

									<v-divider></v-divider>


									<v-card-actions>
										<v-btn rounded @click="click(item)" width="100%"  small color="success" block>
											Ver	detalle
										</v-btn>
									</v-card-actions>
								</v-card>


        





								<!-- <v-card class="mx-auto" outlined>

									<v-btn
										style="
											margin-top: 70px;
											margin-bottom: 20px;
											display: flex;
											justify-content: space-evenly;
										"
										color="light-blue darken-3"
										fab
									>
										<span class="white--text text-h4">
											<b>
												<h2 style="font-size: 40px">
													{{ item.AmountWeight }}
												</h2> </b
											>kg
										</span>
									</v-btn>

									<div
										style="
											background-color: rgba(
												0,
												0,
												0,
												0.5
											);
										"
									></div>
									<v-card
										color="orange

"
										outlined
										width="200"
									>
										<h1 style="font-style: bold">
											{{ item.NameProcess }}
										</h1></v-card
									>
								</v-card> -->
							</v-col>
						</v-row>
						<v-row style="margin: auto">
							<v-col cols="6" align="center" lg="6" md="6" class="pt-0">
								<v-btn
									color="info"
									rounded
									@click="clickAll"
									>Ver Grafico de Barras</v-btn
								>
							</v-col>

							<v-col cols="126" align="center" lg="6" md="6" class="pt-0">
								<v-btn
									color="info"
									rounded
									@click="clickAll2"
									>Ver Grafico Circular</v-btn
								>
							</v-col>
						</v-row>

						<v-dialog
							v-if="openModalGraphOne"
							v-model="openModalGraphOne"
							width="600"
						>
							<v-card>
								<v-row style="margin: auto">
									<h3 class="mt-3 ml-6"></h3>
									<v-spacer> </v-spacer>
									<v-btn
										@click="openModalGraphOne = false"
										class="mt-3 mr-3"
										small
										fab
										color="error"
										><i
											style="font-size: 16px"
											class="fas fa-times"
										></i
									></v-btn>
								</v-row>

								<v-row style="margin: auto">
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-row style="margin: auto">
									<v-col cols="12" lg="12" md="12" class="pt-0">
										<CardBarChart
											:text="'TON BRUTO'"
											:objLineX="objNameTun"
											:objLineY="objWeightGross"
											:objLineY2="objWeightNet"
										></CardBarChart>
									</v-col>
								</v-row>
							</v-card>
						</v-dialog>

						<v-dialog
							v-if="openModalGraph"
							v-model="openModalGraph"
						>
							<v-card>
								<v-row style="margin: auto">
									<h3 class="mt-3 ml-6"></h3>
									<v-spacer> </v-spacer>
									<v-btn
										@click="openModalGraph = false"
										class="mt-3 mr-3"
										small
										fab
										color="error"
										><i
											style="font-size: 16px"
											class="fas fa-times"
										></i
									></v-btn>
								</v-row>

								<v-row style="margin: auto">
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-row style="margin: auto">
									<v-col cols="12" lg="6" md="6" class="pt-0">
										<CardBarChart
											:text="'TON'"
											:objLineX="objNameTun"
											:objLineY="objWeightGross"
										></CardBarChart>
									</v-col>

									<v-col cols="12" lg="6" md="6" class="pt-0">
										<CardLineChart
											:text="'TON NETO DISPONIBLES'"
											:objLineX="objNameTun"
											:objLineY="objWeightNet"
										></CardLineChart>
									</v-col>
								</v-row>
							</v-card>
						</v-dialog>


						<v-dialog
							v-if="openModalGraph2"
							v-model="openModalGraph2"
							width="600"
						>
							<v-card>
								<v-row style="margin: auto">
									<h3 class="mt-3 ml-6"></h3>
									<v-spacer> </v-spacer>
									<v-btn
										@click="openModalGraph2 = false"
										class="mt-3 mr-3"
										small
										fab
										color="error"
										><i
											style="font-size: 16px"
											class="fas fa-times"
										></i
									></v-btn>
								</v-row>

								<v-row style="margin: auto">
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-row style="margin: auto">						

									<v-col cols="12" lg="12" md="12" class="pt-0">
										<CardPieChart	
											:text="'TON NETO DISPONIBLES'"										
											:objLineX="objNameTun"
											:objLineY="objWeightNet"
										></CardPieChart>
									</v-col>
								</v-row>
							</v-card>
						</v-dialog>
					</v-card>

				</div>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import _sStockMaduration from "@/services/FrozenProduction/StockMadurationService";
	import CardBarChart from "@/components/Utils/Cards/CardBarChart";
	import CardLineChart from "@/components/Utils/Cards/CardLineChart";
	import CardPieChart from "@/components/Utils/Cards/CardPieChart";
	export default {
		components: { CardLineChart, CardBarChart, CardPieChart },
		data() {
			return {
				skill: 20,
				itemsDataStock: [],
				objNameTun: [],
				objWeightGross: [],
				objWeightNet: [],
				openModalGraph: false,
				openModalGraphOne: false,
				openModalGraph2: false,
				show: false,
				general: {
					NameProcess: '',
					AmountWeight: 0,
					AmountWeightNet: 0,
				}
			};
		},

		methods: {

			click(item){
				this.objNameTun = []
				this.objWeightGross = []
				this.objWeightNet = []
				console.log("datos", item);
				this.objNameTun.push(item.NameProcess);
				this.objWeightGross.push(item.AmountWeight);
				this.objWeightNet.push(item.AmountWeightNet);
				this.openModalGraphOne = true
			},

			clickAll(){
				this.objNameTun = []
				this.objWeightGross = []
				this.objWeightNet = []
					this.itemsDataStock.forEach((element) => {

						this.objNameTun.push(element.NameProcess);
						this.objWeightGross.push(element.AmountWeight);
						this.objWeightNet.push(element.AmountWeightNet);
					});
					
				this.openModalGraph = true
			},

			clickAll2(){
				this.objNameTun = []
				this.objWeightGross = []
				this.objWeightNet = []
					this.itemsDataStock.forEach((element) => {

						this.objNameTun.push(element.NameProcess);
						this.objWeightGross.push(element.AmountWeight);
						this.objWeightNet.push(element.AmountWeightNet);
					});
					
				this.openModalGraph2 = true
			}
		},

		created() {
			_sStockMaduration.list(this.$fun.getUserID()).then((r) => {
				if (r.status == 200) {

					console.log("datos del stock", r.data);
					this.itemsDataStock = r.data;
					
					this.objNameTun = []
					this.objWeightGross = []
					this.objWeightNet = []
					this.itemsDataStock.forEach((element) => {
						this.general.NameProcess = 'General'
						this.general.AmountWeight += element.AmountWeight;
						this.general.AmountWeightNet += element.AmountWeightNet;

						this.objNameTun.push(element.NameProcess);
						this.objWeightGross.push(element.AmountWeight);
						this.objWeightNet.push(element.AmountWeightNet);
					});

					console.log("datos", this.itemsDataStock);
				}
			});
		},
	};
</script>