import Service from "../Service";
const resource = "frzstockmaduration/";

export default {

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "kardexmaturation", obj, {
            params: { requestID },
        });
    },
};